
import { defineComponent, watch, ref } from "vue";

export default defineComponent({
  name: "upload-legacy-policy-file-error-modal-component",
  components: {},
  props: {
    errors: Array,
  },

  setup(props) {
    // console.log(props);
    const primaryErrors = ref([]);

    watch(
      () => props.errors,
      (newErrors) => {
        primaryErrors.value = newErrors as any;
      }
    );

    return { primaryErrors };
  },
});
